import React from "react";
import PropTypes from "prop-types";
import { useLocation } from "react-router-dom";

const useQuery = () => {
  return new URLSearchParams(window.location.search);
};

function Card(props) {
  const query = useQuery();
  const name = query.get('name') || "Chưa có tên";
  const type = query.get('type') || "bạn";
  return (
    <div className="birthdayCard">
      <div className="cardFront">
        <h3 className="happy">HAPPY BIRTHDAY {name}!</h3>
        <div className="balloons">
          <div className="balloonOne" />
          <div className="balloonTwo" />
          <div className="balloonThree" />
          <div className="balloonFour" />
        </div>
      </div>
      <div className="cardInside">
        <h3 className="back">HAPPY BIRTHDAY {name}!</h3>
        <p>Chào {type},</p>
        <p>
        Chúc mừng sinh nhật! Mong rằng tuổi mới sẽ mang đến cho {type} nhiều niềm vui, hạnh phúc và những thành công rực rỡ. Hy vọng mọi dự định và ước mơ của {type} sẽ sớm trở thành hiện thực. Chúc {type} luôn tỏa sáng và tràn đầy năng lượng như hôm nay! 🎉🌟🎂
        </p>
        <p className="name">Trịnh Tuấn</p>
      </div>
    </div>
  );
}

export default Card;
